import React from 'react';
import { Toolbar, BackButton } from 'react-onsenui';

export default (back = true) => () => {
  return (
    <Toolbar>
      {back ? (<div className="left"><BackButton>Takaisin</BackButton></div>) : undefined}
      <div className="center">Kuormalavakeskus</div>
    </Toolbar>
  )
};
