import React, { Component } from 'react';
import { Page } from 'react-onsenui';
import getToolbar from '../components/getToolbar';

interface Props {
  img: string;
}

class ImageZoom extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar()} id="ImageZoom">
        <img src={this.props.img} alt="img" />
      </Page>
    );
  }
}

export default ImageZoom;
