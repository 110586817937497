import React, { Component, createElement } from 'react';
import { Navigator } from 'react-onsenui';
import LandingPage from './pages/LandingPage';

class App extends Component {
  renderPage(route: any, navigator: any) {
    const props = route.props || {};
    props.navigator = navigator;
    props.key = route.component.name;
    return createElement(route.component, props);
  }

  render() {
    return (
      <Navigator
        id="Navigator"
        renderPage={this.renderPage}
        initialRoute={{ component: LandingPage }}
      />
    );
  }
}

export default App;
