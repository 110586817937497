import React, { Component } from 'react';
import { Page, Card, Select, Input } from 'react-onsenui';
import getToolbar from '../components/getToolbar';

interface State {
  type: string;
  count: number;
  lvm: number;
  stack: number;
}

class CalculatorPage extends Component {
  state: State = {
    type: 'fin',
    count: 17,
    lvm: 0.5,
    stack: 1,
  };

  calc() {
    const { type, count } = this.state;
    let lvm_value = 0.5;
    if (type === 'eur') lvm_value = 0.4;
    const stack = count / 17;
    const lvm = stack * lvm_value;
    this.setState({
      lvm: Math.round(lvm * 10) / 10,
      stack: Math.round(stack * 10) / 10,
    })
  }

  render() {
    return (
      <Page renderToolbar={getToolbar()}>
        <div className="content" id="CalculatorPage">
          <Card>
            <h3>Lavalaskuri</h3>
            <div className="form-group">
              <label>Lavatyyppi</label>
              <Select
                style={{ width: '100%' }}
                modifier="material"
                value={this.state.type}
                onChange={(event) => this.setState({ type: event.target.value }, () => this.calc())}>
                <option value="fin">FIN</option>
                <option value="eur">EUR</option>
              </Select>
            </div>
            <div className="form-group">
              <Input
                float
                type="number"
                style={{ width: '100%' }}
                value={`${this.state.count}`}
                onChange={(event: any) => this.setState({ count: event.target.value }, () => this.calc())}
                modifier='material'
                placeholder='Määrä' />
            </div>
            <p>Täyttämäsi määrä vie tilaa lavametrejä yhteensä: <strong>{this.state.lvm}</strong></p>
            <p>Nippuja joissa on 17 lavaa on yhteensä: <strong>{this.state.stack}</strong></p>
            <hr />
            <img src="/imgs/how-many-eur.png" alt="eur" />
            <img src="/imgs/how-many-fin.png" alt="fin" />
            <img src="/imgs/how-many-sizes.png" alt="size" />
          </Card>
        </div >
      </Page >
    );
  }
}

export default CalculatorPage;
