import React, { Component } from 'react';
import { Page, Card, Input, Button, Toast } from 'react-onsenui';
import getToolbar from '../components/getToolbar';
import Axios from 'axios';

interface Props {
  navigator: any;
}

class OrderPage extends Component<Props> {
  state: any = {
    showToast: false,
  };

  submit() {
    const data = new FormData();
    const element = document.querySelector('input[type=file]') as any;
    const imageData = element.files[0];
    Object.keys(this.state).forEach((k) => data.append(k, this.state[k]));
    data.append('attachment', imageData);
    Axios
      .post(`${window.API}/SellRequests/send`, data)
      .then(() => {
        this.setState({ showToast: true });
        setTimeout(() => this.props.navigator.popPage(), 2500);
      });
  }

  render() {
    return (
      <Page renderToolbar={getToolbar()}>
        <div className="content" id="order">
          <Card>
            <img src="/imgs/lavat-rahaksi-trukki.jpeg" alt="" />
            <p><strong>Myyntikaavake</strong></p>
            <p>Tarjoa tästä ylimääräiset kuormalavat ja kaulukset niin saatte niistä nopeasti ostotarjouksen.</p>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.companyName}
                onChange={(event: any) => this.setState({ companyName: event.target.value })}
                modifier='material'
                placeholder='Yrityksen nimi' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.location}
                onChange={(event: any) => this.setState({ location: event.target.value })}
                modifier='material'
                placeholder='Paikkakunta' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.type}
                onChange={(event: any) => this.setState({ type: event.target.value })}
                modifier='material'
                placeholder='Myytävät lavatyypit' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.quantity}
                onChange={(event: any) => this.setState({ quantity: event.target.value })}
                modifier='material'
                placeholder='Määrä' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.email}
                onChange={(event: any) => this.setState({ email: event.target.value })}
                modifier='material'
                placeholder='Sähköpostiosoite' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.name}
                onChange={(event: any) => this.setState({ name: event.target.value })}
                modifier='material'
                placeholder='Yhteyshenkilön nimi' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.phone}
                onChange={(event: any) => this.setState({ phone: event.target.value })}
                modifier='material'
                placeholder='Puhelinnumero' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.information}
                onChange={(event: any) => this.setState({ information: event.target.value })}
                modifier='material'
                placeholder='Lisätietoja' />
              <p className="muted">Tuleeko jatkuvasti vai kertaluonteisesti, montako lavaa tulee kuukaudessa ym, onko noudolla kiire ym</p>
            </div>
            <div className="form-group">
              <input type="file" id="fileInput" />
            </div>
            <Button onClick={this.submit.bind(this)}>Lähetä</Button>
          </Card>
        </div>
        <Toast isOpen={this.state.showToast}>Tilauksesi on lähetetty onnistuneesti!</Toast>
      </Page>
    );
  }
}

export default OrderPage;
