import React, { Component } from 'react';
import { Page } from 'react-onsenui';
import CardList from '../components/CardList';
import ProductPage from './ProductPage';
import getToolbar from '../components/getToolbar';
import catalog from '../catalog.json';

interface Props {
  navigator: any;
}

class CatalogPage extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar()}>
        <div className="content">
          {catalog.map((row: any, i: number) => <CardList
            key={row.title}
            img={row.img ? `/imgs/products/${row.img}` : undefined}
            lg
            text={row.title}
            description={`Paino: ${row.weight || '-'}, ${row.duration || ''}`}
            onClick={() => this.props.navigator.pushPage({ component: ProductPage, props: { productId: i } })} />)}
        </div>
      </Page>
    );
  }
}

export default CatalogPage;
