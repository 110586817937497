import React from 'react';
import { Card } from 'react-onsenui';

interface Props {
  text: string;
  description: string;
  onClick(): void;
  img?: string;
  lg?: boolean;
}

const CardList = ({ text, description, onClick, img, lg }: Props) => (
  <span key={text} onClick={onClick}>
    <Card className={`card-list ${lg ? 'card-list-lg' : ''}`}>
      <img src={img || "https://via.placeholder.com/50"} className="list-item__thumbnail" alt="thumbnail" />
      <div>
        <div>{text}</div>
        <div>{description}</div>
      </div>
    </Card>
  </span>
);

export default CardList;
