import React, { Component } from 'react';
import { Page, Card } from 'react-onsenui';
import getToolbar from '../components/getToolbar';

interface Props {
  navigator: any;
}

class WordsPage extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar()} id="WordsPage">
        <div className="content">
          <Card>
            <h3>KUORMALAVASANASTOA</h3>
            <p>Tähän on kerätty kuormalavasanoja jotka käytetään kuormalavojen käsittelyssä tai kuljettamisessa. Vihdoinkin ymmärrät mistä varastonmiehet ja kuljettavat puhuvat.</p>
            <p><strong>Nuppi</strong></p>
            <p>Kuorma-auto johon mahtuu noin 17 lavanippua.</p>
            <p><strong>PP tai puoliperä tai puolikas</strong></p>
            <p>Puoliperä eli kuorma-auton kärry joka on noin 13 m pitkä ja eur lavapohjia 33 kpl</p>
            <p><strong>Jakari</strong></p>
            <p>Jakeluauto joka jakaa tavarat</p>
            <p><strong>Standardilava</strong></p>
            <p>Standardilavalla tarkoitetaan yleensä EUR lavaa joka on vaihtokelpoinen. Voidaan tarkoittaa myös standardikokoista FIN lavaa mutta silloin sanotaan yleensä standardi FIN lavaa.</p>
            <p><strong>Vasikka</strong></p>
            <p>Kuusi metriä pitkä lyhyempi keskiakseliperävaunu. Ei ole niin yleisesti käytetty vaunu Suomessa.</p>
            <p><strong>Perälauta</strong></p>
            <p>Kuorma-auton takana oleva lastaustaso jonka voi nostaa ja laskea.</p>
            <p><strong>Lavametri (lvm)</strong></p>
            <p>Metrin pituinen osa auton tai trailerin kuormaustilasta koko kuormatilan leveydeltä ja korkeudelta.</p>
            <p><strong>Käyttöasentoon pinottu</strong></p>
            <p>Lavat ovat pinottu päällekkäin noin 17 kpl nippuihin josta on helppo ottaa lavat yksitellen esim trukilla. EUR ja FIN lavat pinotaan yleensä käyttöasentoon.</p>
            <p><strong>Sisäkkäin pinottu</strong></p>
            <p>Lavat pinottu sisäkkäin yleensä noin 25 kpl nippuihin. Kertalavat pinotaan yleensä aina sisäkkäin jotta lavoja mahtuu enemmän kuorma-autoon.</p>
            <p><strong>Siivekkeellinen lava</strong></p>
            <p>Lava jonka palikat voivat ovat hieman sisempänä eli eivät ole ihan kulmissa.</p>
            <p><strong>Ympärijalkanen lava</strong></p>
            <p>Lava jossa jalkalauta kiertää lavan ympäri pohjassa eli yleensä 5 jalkalautaa normaalin 3 sijaan.</p>
            <p><strong>Nelitielava</strong></p>
            <p>Lava jonka voi nostaa pumpppukärryllä kaikista neljästä sivuista.</p>
            <p><strong>Kaksitielava</strong></p>
            <p>Lava jonka voi vaan nostaa pumppukärryllä 2 sivusta eli yleensä konttilava.</p>
            <p><strong>Rocla</strong></p>
            <p>Pumppukärry jolla lavat siirretään autoon sisään tai autosta ulos. Voi olla myös sähkökäyttöinen. On myös Suomen johtava merkki pumppukärryissä.</p>
            <p><strong>Aluslava tai orjalava</strong></p>
            <p>Tuotteiden alla oleva lava kutsutaan aluslavaksi tai orjalavaksi. Esim myymälälavanippujen alla on yleensä eur-kokoinen aluslava ja sen päällä 2 tornia myymälälavoja</p>
            <p><strong>Torni</strong></p>
            <p>Yksi korkea lavanippu kutsutaan lavatorniksi. Se voi olla yksi nippu jossa yleensä 17 kpl lavaa tai varastossa esim 2 nippua päällekkäin.</p>
            <p><strong>Rahtari</strong></p>
            <p>rekka-auton kuljettaja</p>
            <p><strong>Rekka</strong></p>
            <p>Rekalla on tarkoitettu alun perin puoliperävaunua. Nykyään sanalla viitataan myös täysperävaunuun.</p>
            <p><strong>Laituri</strong></p>
            <p>Terminaalin tai varaston lastaus- tai purkupaikka</p>
            <p><strong>Pässi</strong></p>
            <p>Akkukäyttöinen pumppukärry jonka takana voi seistä. Käytetään terminaaleissa kuormalavojen siirtoon</p>
            <p><strong>Tuplapässi</strong></p>
            <p>Näillä pumppukärryillä voi siirtää 2 lavanippua kerralla. Haarukat eli piikit ovat yleensä 2400 mm pitkät</p>
            <p><strong>Juitsari</strong></p>
            <p>Kuljetusliikkeen ajojärjestelijää kutsutaan yleensä juitsariksi.</p>
            <p><strong>Eurolava</strong></p>
            <p>Vaihtokelpoinen EUR lava jonka mitat on 800x1200</p>
            <p><strong>Teholava, puolilava tai myymälälava</strong></p>
            <p>Kertalava 800x600mm. Usein käytetty myymälöissä josta myydään suoraan tuotteita lavasta asiakkaalle.</p>
            <p><strong>Kertalava</strong></p>
            <p>Kertalava eli kevyempi kuin EUR ja FIN lava.</p>
            <p><strong>Finni-lava, VR lava</strong></p>
            <p>FIN lava jonka mitta on 1000x1200.On vaihtokelpoinen standardilava.</p>
            <p><strong>Otatko pumput</strong></p>
            <p>Tuotko pumppukärryn</p>
            <p><strong>Heittää lavoja, lempata lavoja</strong></p>
            <p>Tarkoittaa lavojen lajittelua tai pinoamista</p>
            <p><strong>Ihan rimpula</strong></p>
            <p>Todella kevyt lava</p>
            <p><strong>Romulava</strong></p>
            <p>Kuormalava joka on vääränmittainen, liian rikkinäinen tai liian kevyt ja siitä tehdään yleensä haketta.</p>
            <p><strong>CHEPPI</strong></p>
            <p>Sininen vuokralava. Palautetaan aina Chepille.</p>
            <p><strong>Vuokralava</strong></p>
            <p>Lava joka on suljetussa kierrossa eli ne pitää palauttaa omistajille suoraan. Siniset Chep lavat ovat vuokralavoja.</p>
            <p><strong>Panimo-lava tai Pan-lava</strong></p>
            <p>Panimoiden käyttämä lava jonka mitat on 1200x900. Kiertävät panimoiden ja kauppojen välissä suljetussa systeemissä.</p>
            <p><strong>IPPC- tai tähkäleima</strong></p>
            <p>Ippc leimaa vaaditaan puupakkauksissa jotka menevät vientiin esim jenkkeihin ja australiaan.</p>
          </Card>
        </div>
      </Page>
    );
  }
}

export default WordsPage;
