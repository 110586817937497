import React, { Component } from 'react';
import { Page, Card } from 'react-onsenui';
import getToolbar from '../components/getToolbar';
import ImageZoom from './ImageZoom';

interface Props {
  navigator: any;
}

class TradeableGuidePage extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar()} id="TradeableGuide">
        <div className="content">
          <Card>
            <h3>EUR-EPAL lavan Vaihtokelpoisuuden määrittely</h3>
            <p>Eur-lava, Euro-lava tai EPAL lava on European Pallet Association:in (EPAL) ylläpitämä standardi. Lavat jotka ovat standardin mukaisia voidaan käyttää vaihtokelpoisena lavana.</p>
            <p>EUR/EPAL-lava on 1200 mm × 800 mm × 144 mm; se on nelitie lava ja on tehty puusta. Lava painaa noin 25 kg. Turvallinen kantavuus on noin 1500 kg.</p>
            <img src="/imgs/lavat.png" onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/lavat.png` } })} alt="" />
            <img src="/imgs/size.png" onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/size.png` } })} alt="" />
            <p><strong>EUR kuormalava EI ole vaihtokelpoinen jos</strong></p>
            <ul>
              <li>EUR tai EPAL merkintä puuttuu</li>
              <li>Jokin lauta on poikki tai puuttuu</li>
              <li>Palikka tai koko lava on vääntynyt</li>
              <li>Laudoista on poissa enemmän kuin 22 mm</li>
              <li>Lava on likainen esim öljyinen tai homeinen</li>
              <li>Lava on maalattu, liian tumma tai musta</li>
              <li>Lavassa on tarroja, muovia tai nauloja pystyssä</li>
              <li>Lava on kertalava tai siihen on käytetty kertalavan lautaa tai palikoita korjaamiseen</li>
              <li>EUR C ja kertalava EI ole vaihtokelpoinen lava.</li>
            </ul>
            <p><strong>Kuormalava on korjauskelvoton jos:</strong></p>
            <p>Sen korjaamiseksi standardin-mukaiseksi pitää uusia yhteensä enemmän kuin kaksi kulma- tai välitukea, tai yhteensä enemmän kuin kolme jalka- tai kansilautaa Jos yksikin poikkilauta on poikki tai vioittunut niin, ettei siihen voi kiinnittää muita lavan osia.</p>
            <img src="/imgs/tradeable.png" onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/tradeable.png` } })} alt="" />
            <img src="/imgs/not-tradeable.png" onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/not-tradeable.png` } })} alt="" />
          </Card>
        </div>
      </Page>
    );
  }
}

export default TradeableGuidePage;
