import React, { Component } from 'react';
import { Page } from 'react-onsenui';
import CatalogPage from './CatalogPage';
import CardList from '../components/CardList';
import getToolbar from '../components/getToolbar';
import OrderPage from './OrderPage';
import TradeableGuidePage from './TradeableGuidePage';
import IPPCPage from './IPPCPage';
import WordsPage from './WordsPage';
import CalculatorPage from './CalculatorPage';

interface Props {
  navigator: any;
}

const menu = [
  {
    text: 'Lavatyypit',
    description: 'Tutustu erillaisiin lavatyyppeihin.',
    img: '/imgs/lavat.png',
    component: CatalogPage,
  },
  {
    text: 'Lavat rahaksi',
    description: 'Myy meille tarpeettomat lavat ja kaulukset',
    img: '/imgs/lavat-rahaksi.jpg',
    component: OrderPage,
  },
  {
    text: 'Vaihtokelpoisuuden määrittely',
    description: 'Lisätietoja vaihtokelpoisuudesta.',
    img: '/imgs/tradeable.png',
    component: TradeableGuidePage,
  },
  {
    text: 'Lavalaskuri',
    description: 'Lavalaskuri',
    img: '/imgs/lavalaskuri-kuvake.png',
    component: CalculatorPage,
  },
  {
    text: 'IPPC',
    description: 'IPPC leima tai tähkäleima.',
    img: '/imgs/tahka-thumb.png',
    component: IPPCPage,
  },
  {
    text: 'Kuormalavasanastoa',
    description: 'Kuormalavasanastoa',
    img: '/imgs/kuormalavasanasto-kuvake.png',
    component: WordsPage,
  }
];

class LandingPage extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar(false)}>
        <div className="content">
          {menu.map((row) => <CardList
            key={row.text}
            text={row.text}
            img={row.img}
            description={row.description}
            onClick={() => this.props.navigator.pushPage({ component: row.component })} />)}
        </div>
      </Page>
    );
  }
}

export default LandingPage;
