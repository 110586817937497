import React, { Component } from 'react';
import { Page, Card, Input, Button, Toast } from 'react-onsenui';
import getToolbar from '../components/getToolbar';
import catalog from '../catalog.json';
import Axios from 'axios';
import ImageZoom from './ImageZoom';

interface Props {
  productId: number;
  navigator: any;
}

class ProductPage extends Component<Props> {
  state: any = {
    showToast: false,
  };

  submit() {
    console.log('Submit');
    Axios
      .post(`${window.API}/Orders/send`, this.state)
      .then(() => {
        this.setState({ showToast: true });
        setTimeout(() => this.props.navigator.popPage(), 2500);
      });
  }

  componentDidMount() {
    const { productId } = this.props;
    const product = catalog[productId] as any;
    this.setState({ type: product.title });
  }

  render() {
    const { productId } = this.props;
    const product = catalog[productId] as any;
    return (
      <Page renderToolbar={getToolbar()}>
        <div className="content" id="product">
          <Card>
            <img
              src={product.img ? `/imgs/products/${product.img}` : "https://via.placeholder.com/500"}
              onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/products/${product.img}` } })}
              alt="img" />
            <p><strong>{product.title}</strong></p>
            <p><strong>Paino:</strong> {product.weight}</p>
            <p><strong>Koko:</strong> {product.size}</p>
            <p><strong>Kestävyys:</strong> {product.duration}</p>
            <p><strong>Vaihtokelpoinen:</strong> {product.tradeable ? 'Kyllä' : 'Ei'}</p>
            <p><strong>Lisätiedot:</strong></p>
            <ul>
              {product.infos.map((s: string) => <li key={s}>{s}</li>)}
            </ul>
            <hr />
            {product.images.length ? (
              <div>
                <h3><strong>Kuvat</strong></h3>
                {product.images.map((img: string) => <img
                  src={`/imgs/products/${img}`}
                  onClick={() => this.props.navigator.pushPage({ component: ImageZoom, props: { img: `/imgs/products/${img}` } })}
                  className="img-list" key={img} alt="img" />)}
                <hr />
              </div>
            ) : undefined}
            <h3><strong>Tarjouspyyntö</strong></h3>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.companyName}
                onChange={(event: any) => this.setState({ companyName: event.target.value })}
                modifier='material'
                placeholder='Yrityksen nimi' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.location}
                onChange={(event: any) => this.setState({ location: event.target.value })}
                modifier='material'
                placeholder='Paikkakunta' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.type}
                onChange={(event: any) => this.setState({ type: event.target.value })}
                modifier='material'
                placeholder='Lavatyyppi' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.quantity}
                onChange={(event: any) => this.setState({ quantity: event.target.value })}
                modifier='material'
                placeholder='Määrä' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.email}
                onChange={(event: any) => this.setState({ email: event.target.value })}
                modifier='material'
                placeholder='Sähköpostiosoite' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.name}
                onChange={(event: any) => this.setState({ name: event.target.value })}
                modifier='material'
                placeholder='Yhteyshenkilön nimi' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.phone}
                onChange={(event: any) => this.setState({ phone: event.target.value })}
                modifier='material'
                placeholder='Puhelinnumero' />
            </div>
            <div className="form-group">
              <Input
                float
                style={{ width: '100%' }}
                value={this.state.information}
                onChange={(event: any) => this.setState({ information: event.target.value })}
                modifier='material'
                placeholder='Lisätietoja' />
            </div>
            <Button onClick={this.submit.bind(this)}>Lähetä</Button>
          </Card>
        </div>
        <Toast isOpen={this.state.showToast}>Tilauksesi on lähetetty onnistuneesti!</Toast>
      </Page>
    )
  };
}
export default ProductPage;
