import React, { Component } from 'react';
import { Page, Card } from 'react-onsenui';
import getToolbar from '../components/getToolbar';

interface Props {
  navigator: any;
}

class IPPCPage extends Component<Props> {
  render() {
    return (
      <Page renderToolbar={getToolbar()} id="TradeableGuide">
        <div className="content">
          <Card>
            <h3>IPPC leima tai tähkäleima</h3>
            <p>EU:n ulkopuolisiin maihin vietävissä lähetyksissä käytettävän puisen pakkausmateriaalin on pääsääntöisesti oltava ISPM 15 -standardin mukaista. Standardi vaatii, että puinen pakkausmateriaali on</p>
            <ul>
              <li>kuoretonta,</li>
              <li>käsiteltyä kasvintuhoojien torjumiseksi (lämpökäsittely 56 celsiusastetta 30 min)</li>
              <li>merkittyä standardin mukaisella merkinnällä</li>
            </ul>
            <p>Standardi on laadittu, jotta metsä- ja puutavaratuholaiset eivät leviäisi kansainvälisessä kaupassa käytettävän puisen pakkausmateriaalin välityksellä. Standardi on FAO:n laatima.</p>
            <p>Jos yrityksesi käyttää puista pakkausmateriaalia EU:n ulkopuolisiin maihin vietävissä lähetyksissä, selvitä ennen vientiä vaatiiko vastaanottajamaa ISPM 15 -standardin mukaisen käsittelyn ja merkinnän.</p>
            <img src="/imgs/tahka.png" alt="" />
          </Card>
        </div>
      </Page>
    );
  }
}

export default IPPCPage;
